@use "bootstrap-icons/font/bootstrap-icons";
@import "bootstrap/scss/bootstrap";

:root {
  --app-bg-color: rgb(16, 16, 20, var(--bs-bg-opacity, 1));
  --app-bg-control: rgb(58, 58, 64, var(--bs-bg-opacity, 1));
  --app-text-control: white;
  --app-disabled: gray;
  --app-highlight: rgb(100, 217, 255, var(--bs-bg-opacity, 1));
  --app-bg-window: black;
  --app-font: 'Roboto';
  --app-font-size: 10pt;
  --app-text-color: #f0f0f0;
  --app-button-font-size: 18pt;
  --app-button-size: 24pt;

  --bs-gradient: linear-gradient(180deg, rgb(0, 0, 0 / 0.2), rgb(128, 128, 128 / 0) 50%, rgb(255, 255, 255 / 0.05))
}

body {
  font-family: var(--app-font), sans-serif;
  font-size: var(--app-font-size);
  background-color: var(--app-bg-color);
  color: var(--app-text-color);
}

.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.scrollable {
  overflow-y: auto !important;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.app-bg-control {
  background: var(--app-bg-control) !important;
}

.app-control {
  background: var(--app-bg-control);
  color: var(--app-text-control);

  &.selected {
    color: var(--app-highlight);
  }
}

.d-contents {
  display: contents !important;
}

.fill {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.row-divider {
  display: inline-block;
  margin: auto 2ch;
  width: 0;
  height: 90%;
  border-left: 2px solid #00000088;
  border-right: 2px solid #FFFFFF44;
}
